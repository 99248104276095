<template>
    <div>
        <h2>Import CSV File</h2>

        <p>
            The file should be exported from <a
                href="https://docs.google.com/spreadsheets/d/1uJTDWtPP6bY90zpIKfFf2OwvohyJWyIPurtLWkUJmq4/edit?usp=sharing"
                target="_blank">this Google sheet</a>.
            To export, do the following:
        <ol>
            <li>Select de entire content of one tab with questions (so one language).</li>
            <li>Select <strong>File</strong> &gt; <strong>Download</strong> &gt; <strong>CSV</strong></li>
        </ol>
        </p>
        <p>

            <file-upload ref="upload" v-model="files" :drop="true" :drop-directory="false" @input-file="inputFile"
                class="drop-area">
                Drag your CSV file here or click to select a file.
                <div v-if="files.length" class="file-preview">
                    <p>Selected file: {{ files[0].name }}</p>
                </div>
            </file-upload>
        </p>
        <p>
            <label for="locale-select">Which language is this file in?</label>

            <b-dropdown id="locale-select" :text="selectedLocale || 'Select language'" class="m-md-2">
                <b-dropdown-item @click="setLocale('nl')">NL - Nederlands</b-dropdown-item>
                <b-dropdown-item @click="setLocale('en')">EN - English</b-dropdown-item>
            </b-dropdown>

            <button @click="submitFile" class="button-main" :disabled="!files.length">Upload</button>
        </p>

        <div v-if="message" class="message" :class="errors.length > 0 ? 'error' : ''">
            {{ message }}
            <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import { uploadCsv, runQualityChecks } from '@/repository/apiService';
import FileUpload from 'vue-upload-component';

export default {
    components: {
        FileUpload
    },
    data() {
        return {
            files: [],
            message: '',
            errors: [],
            selectedLocale: null
        };
    },
    methods: {
        inputFile(newFile, oldFile) {
            if (newFile && !oldFile) {
                // New file selected
            }
        },
        setLocale(locale) {
            this.selectedLocale = locale;
        },
        async submitFile() {
            if (!this.files.length) return;
            if (!this.selectedLocale) {
                this.message = 'Please select a language before uploading.';
                return;
            }

            const formData = new FormData();
            formData.append('csvFile', this.files[0].file);
            formData.append('locale', this.selectedLocale);

            try {
                const response = await uploadCsv(formData);

                if (response.success) {
                    this.message = 'Upload successful, questions added! Running quality checks...';
                    this.files = []; // Reset files after successful upload
                    this.selectedLocale = '';
                    this.errors = [];

                    this.check();
                } else {
                    this.message = response.error;
                    this.errors = response.data;
                }
            } catch (error) {
                this.message = error.message;
            }
        },
        async check() {
            try {
                await new Promise(resolve => setTimeout(resolve, 1000));
                const response = await runQualityChecks();
                if (response.success) {
                    this.message = 'Upload successful, questions added! Quality checks completed.';
                } else {
                    this.message = 'Upload succesful, but quality checks failed. Please fix these errors immediately: ';
                    this.errors = response.data;
                }
            } catch (error) {
                this.message = 'Quality checks failed: ' + error.message;
            }
        }
    }
};
</script>

<style>
.drop-area {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.message {
    margin-top: 20px;
    color: #007bff;
}

.error {
    color: rgb(156, 27, 27) !important;
}
</style>
