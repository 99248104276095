import { createRouter, createWebHistory } from 'vue-router'
import LoginView from './views/LoginView.vue'
import RegisterView from './views/RegisterView.vue'
import DisciplinesView from './views/DisciplinesView.vue'
import QuestionView from './views/QuestionView.vue'
import UploadView from './views/UploadView.vue'
import MediaView from './views/MediaView.vue'
import BattleView from './views/BattleView.vue'
import BattleDetailView from './views/BattleDetailView.vue'
import UsersView from './views/UsersView.vue'
import PromosView from './views/PromosView.vue'
import VouchersView from './views/VouchersView.vue'

import { getAuth, onAuthStateChanged } from 'firebase/auth';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DisciplinesView,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/questions',
    name: 'Questions',
    component: QuestionView,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [{
      path: '/questions/:id',
      name: 'QuestionPerDiscipline',
      component: QuestionView,
      meta: { requiresAuth: true, requiresAdmin: true }
    }]
  },
  {
    path: '/import',
    name: 'Import',
    component: UploadView,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/media',
    name: 'Media',
    component: MediaView,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/battles',
    name: 'Battles',
    component: BattleView,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/battle/:id',
    name: 'BattleDetail',
    component: BattleDetailView,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/users',
    name: 'Users',
    component: UsersView,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/promos',
    name: 'Promos',
    component: PromosView,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/vouchers',
    name: 'Vouchers',
    component: VouchersView,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  /* scrollBehavior(to, from, savedPosition) {
    // console.log("scrollBehavior", to, from, savedPosition);
    // always scroll 10px above the element #main
    return {
      el: '#content',
      top: -10,
      behavior: 'smooth',
    }
  }, */
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
  const auth = getAuth();
  // const isAuthenticated = auth.currentUser;

  if (requiresAuth) {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (requiresAdmin) {
          // Check if the user is an admin
          auth.currentUser.getIdTokenResult()
            .then((idTokenResult) => {
              if (idTokenResult.claims.admin) {
                // User is an admin
                next();
              } else {
                // User is signed in but not an admin, divert to login page
                next('/login');
              }
            });
        } else {
          // User is signed in and that is enough, proceed to the route
          next();
        }
      } else {
        // No user is signed in, redirect to login
        next('/login');
      }
    });
  } else {
    // No login required, proceed to the route
    next();
  }
});

export default router;
