<template>
  <div>
    <NavBar />
    <div class="container mt-3">
      <router-view />

      <img v-if="!production" class="banner-overlay" src="@/assets/banner_staging.png" alt="[TESTOMGEVING]"
        title="App running in test mode" />
    </div>
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";

export default {
  name: "app",
  components: {
    NavBar
  },
  data() {
    return {
      production: process.env.NODE_ENV === "production"
    }
  }
};
</script>

<style scoped>
.container h2 {
  text-align: center;
  margin: 25px auto;
}

.banner-overlay {
  position: fixed;
  top: 0;
  right: 0;
  pointer-events: none;
  width: 10em;
  z-index: 10;
}
</style>
