<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">Log in</div>
                    <div class="card-body">
                        <div v-if="error" class="alert alert-danger">{{ error }}</div>

                        <Button v-if="enableGoogle" @click="handleGoogleLogin">
                            Login with Google
                        </Button>

                        <form action="#" @submit.prevent="Login">
                            <div class="form-group row">
                                <label for="email" class="col-md-4 col-form-label text-md-right">Email</label>

                                <div class="col-md-6">
                                    <input id="email" type="email" class="form-control" name="email" value required
                                        autofocus v-model="email" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                                <div class="col-md-6">
                                    <input id="password" type="password" class="form-control" name="password" required
                                        v-model="password" />
                                </div>
                            </div>

                            <div class="form-group row mb-0">
                                <div class="col-md-8 offset-md-4">
                                    <button type="submit" class="btn btn-primary">Log in</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { getAuth, signInWithPopup, signOut, GoogleAuthProvider } from "firebase/auth";

const ENABLE_GOOGLE_LOGIN = false;

export default {
    name: "LoginComponent",
    setup() {
        const store = useStore();
        const router = useRouter();
        const email = ref('');
        const password = ref('');
        const error = ref(null);
        const Login = async () => {
            try {
                await store.dispatch('logIn', {
                    email: email.value,
                    password: password.value
                });
                router.push('/');
            } catch (err) {
                error.value = err.message;
            }
        }
        return {
            email,
            password,
            error,
            Login
        };
    },
    data() {
        return {
            enableGoogle: ENABLE_GOOGLE_LOGIN
        }
    },
    methods: {
        handleGoogleLogin() {
            if (!ENABLE_GOOGLE_LOGIN) {
                window.alert('Google login is not enabled');
                return;
            }
            const auth = getAuth();
            // Create an instance of the Google provider object:
            const provider = new GoogleAuthProvider();
            signInWithPopup(auth, provider)
                .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    // The signed-in user info.
                    const user = result.user.displayName;
                    console.log(user, token);
                }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.customData.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    // ...

                    console.log(errorCode, errorMessage, email, credential);
                });
        },
        handleSignOut() {
            const auth = getAuth();
            signOut(auth).then(() => {
                // Navigate back to login screen
                this.$router.push('/login');
            }).catch((error) => {
                console.log(error);
            });
        }
    }
};
</script>
