import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import 'firebase/auth';

// Compendium DEV
/* const firebaseConfig = {
    apiKey: "AIzaSyD6D-hpbhZHNGVmVOLFCvEyuCidKEERNRs",
    authDomain: "compendiumdev-3ca30.firebaseapp.com",
    projectId: "compendiumdev-3ca30",
    storageBucket: "compendiumdev-3ca30.appspot.com",
    messagingSenderId: "444639850019",
    appId: "1:444639850019:web:f22bc53016abe683858b96"
}; */

const firebaseConfig = {
    apiKey: "AIzaSyDl3h80q6ifeZb2fUVL0qtnVXPrp85Ed4g",
    authDomain: "compendium-app-6b48f.firebaseapp.com",
    projectId: "compendium-app-6b48f",
    storageBucket: "compendium-app-6b48f.appspot.com",
    messagingSenderId: "132431107138",
    appId: "1:132431107138:web:c229d16c187f30f69149db",
    measurementId: "G-8DKVL22TM2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
