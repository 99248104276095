<template>
    <div class="upload-area">
        <h2>Upload images for Questions</h2>

        <p>
            Here you can upload images that are associated with questions.
            Make sure the filename matches the one specified in <a
                href="https://docs.google.com/spreadsheets/d/1uJTDWtPP6bY90zpIKfFf2OwvohyJWyIPurtLWkUJmq4/edit?usp=sharing"
                target="_blank">this Google sheet</a>.
            Note: if multiple questions or translations use the same image with the same filename, you only need to upload
            it once.
        </p>
        <p>

            <file-upload ref="upload" v-model="files" :drop="true" :drop-directory="false" @dragover.prevent
                @drop.prevent="handleDrop" @input-file="inputFile" class="drop-area">
                Drag your JPEG or PNG file here or click to select a file.
                <div v-if="files.length" class="file-preview">
                    <p>Selected image: {{ files[0].name }}</p>
                    <img v-if="isImage(files[0])" :src="fileUrl(files[0])" class="thumbnail" />
                </div>
            </file-upload>
            <br />
            <button @click="submitFile" class="button-main" :disabled="!files.length">Upload</button>
        </p>

        <div v-if="message" class="message">{{ message }}</div>
    </div>

    <MediaQuestionView />

    <AllMediaView />
</template>

<script>
import { uploadMedia } from '@/repository/apiService';
import FileUpload from 'vue-upload-component';
import MediaQuestionView from './MediaQuestionView.vue';
import AllMediaView from './AllMediaView.vue';

export default {
    components: {
        FileUpload,
        MediaQuestionView,
        AllMediaView
    },
    data() {
        return {
            files: [],
            message: '',
        };
    },
    methods: {
        inputFile(newFile, oldFile) {
            if (newFile && !oldFile) {
                // New file selected
            }
        },
        fileUrl(file) {
            return URL.createObjectURL(file.file);
        },
        isImage(file) {
            return file.type.startsWith('image/');
        },
        handleDrop(event) {
            event.preventDefault();
            // You can handle file data from event here if needed
            // For now, we're just preventing the default behavior
        },
        async submitFile() {
            if (!this.files.length) return;

            const formData = new FormData();
            formData.append('imageFile', this.files[0].file);

            try {
                const response = await uploadMedia(formData);

                this.message = response.success ? 'Upload successful!' : 'Error: ' + response.message;
                this.files = []; // Reset files after successful upload
            } catch (error) {
                this.message = error.message;
            }
        }
    }
};
</script>

<style>
.upload-area {
    background: #f0f0f0;
    padding: 1em;
    margin-bottom: 2em;
    border-radius: 0.5em;
}

.file-preview {
    margin-top: 10px;
}

.thumbnail {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
}
</style>
