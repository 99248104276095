<template>
    <div>
        <h2>Battles</h2>

        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Discipline</th>
                    <th>Description</th>
                    <th>Started</th>
                    <th>Ended</th>
                    <th>Active</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="battle in battles" :key="battle.id">
                    <td>{{ battle.id }}</td>
                    <td>
                        <router-link :to="{ name: 'BattleDetail', params: { id: battle.id } }">{{
                    battle.title }}</router-link>
                    </td>
                    <td v-if="battle.disciplineId">
                        <router-link :to="{ name: 'QuestionPerDiscipline', params: { id: battle.disciplineId } }">{{
                    battle.disciplineId }}</router-link>
                    </td>
                    <td v-else>
                        N/A
                    </td>
                    <td>{{ battle.description }}</td>
                    <td>{{ battle.startAt }}</td>
                    <td>{{ battle.endAt }}</td>
                    <td v-if="battle.isActive == 1">
                        <strong style="color: red;">ACTIVE BATTLE</strong><br />
                        <button class="button-danger" @click="stopBattle(battle.id)">Stop</button>
                    </td>
                    <td v-else>
                        <button class="button-main" @click="startBattle(battle.id)">Start</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { fetchBattles, startBattle, stopBattle } from '@/repository/apiService';

export default {
    data() {
        return {
            battles: [],
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.battles = await fetchBattles();
            } catch (error) {
                // Handle errors, e.g., show an error message
                console.log(error);
            }
        },
        async startBattle(battleId) {
            try {
                await startBattle(battleId);
            } catch (error) {
                console.log(error);
            }
            this.refresh();
        },
        async stopBattle(battleId) {
            try {
                await stopBattle(battleId);
            } catch (error) {
                console.log(error);
            }
            this.refresh();
        }
    }
};
</script>

<style>
/* Your table styles */
</style>
