<template>
    <div>
        <h2>Add promotion</h2>

        <p>
            Here you can configure text and images that are shown when the user completes a level.
            For each discipline, only the latest promo is shown.
        </p>

        <form @submit.prevent="submitForm">
            <div class="form-group">
                <label for="title">Title</label>
                <input type="text" id="title" v-model="promo.title" class="form-control"
                    placeholder="Title (shown in the app)">
            </div>
            <!-- <div class="form-group">
                <label for="description">Description (optional)</label>
                <textarea id="description" v-model="battle.description" class="form-control"></textarea>
            </div> -->
            <div class="form-group">
                <label for="ctatext">Button text</label>
                <input type="text" id="ctatext" v-model="promo.ctaText" class="form-control" placeholder="">
            </div>
            <div class="form-group">
                <label for="ctaurl">Button link</label>
                <input type="text" id="ctaurl" v-model="promo.ctaUrl" class="form-control" placeholder="https://...">
            </div>
            <br />

            <file-upload ref="upload" v-model="files" :drop="true" :drop-directory="false" @dragover.prevent
                @drop.prevent="handleDrop" @input-file="inputFile" class="drop-area">
                Drag your JPEG or PNG file here or click to select a file.
                <div v-if="files.length" class="file-preview">
                    <p>Selected image: {{ files[0].name }}</p>
                    <img v-if="isImage(files[0])" :src="fileUrl(files[0])" class="thumbnail" />
                </div>
            </file-upload>
            <br />
            <p>
                Please select a language and then a discipline for this promotion:
            </p>
            <p>

                <b-dropdown id="locale-select" :text="selectedLocale || 'Select language'" class="m-md-2">
                    <b-dropdown-item @click="setLocale('nl')">NL - Nederlands</b-dropdown-item>
                    <b-dropdown-item @click="setLocale('en')">EN - English</b-dropdown-item>
                </b-dropdown>

                <b-dropdown id="discipline-select" :text="selectedDiscipline || 'Select discipline'" class="m-md-2">
                    <b-dropdown-item v-for="discipline in disciplines" :key="discipline.id"
                        @click="setDiscipline(discipline)">{{ discipline.title }}</b-dropdown-item>
                </b-dropdown>

            </p>

            <button type="submit" class="button-main" :disabled="!isFormComplete()">Create promo</button>
        </form>

        <div v-if="message" class="message">{{ message }}</div>

        <br />
        <h2>Promotions</h2>

        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Discipline</th>
                    <th>Promo text</th>
                    <th>Image</th>
                    <th>Button</th>
                    <th>Created</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="promo in promos" :key="promo.id">
                    <td>{{ promo.id }}</td>
                    <td>{{ promo.disciplineId }}</td>
                    <td>{{ promo.title }}</td>
                    <td class="td-image">
                        <img v-if="promo.imageUrl" :src="fixImageUrl(promo.imageUrl)" :alt="promos.title" width="100">
                    </td>
                    <td>
                        <a :href="promo.ctaUrl" target="_blank">{{ promo.ctaText }}</a>
                    </td>
                    <td>{{ promo.createdAt }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { fetchPromos, fetchDisciplines, prefixImageUrl, uploadPromo } from '@/repository/apiService';
import FileUpload from 'vue-upload-component';

export default {
    components: {
        FileUpload,
    },
    data() {
        return {
            promo: {
                locale: 'nl',
                disciplineId: null,
                title: '',
                description: '',
                ctaText: null,
                ctaUrl: null
            },
            promos: [],
            files: [],
            message: '',
            success: false,
            selectedLocale: null,
            selectedDiscipline: null,
            disciplines: []
        };
    },
    created() {
        this.fetchPromos();
    },
    methods: {
        setLocale(locale) {
            this.selectedLocale = locale;
            this.fetchDisciplines();
        },
        setDiscipline(discipline) {
            this.promo.disciplineId = discipline.id;
            this.selectedDiscipline = discipline.title;
        },
        async fetchDisciplines() {
            try {
                this.disciplines = await fetchDisciplines(this.selectedLocale);
            } catch (error) {
                console.error('Error fetching disciplines:', error)
            }
        },
        inputFile(newFile, oldFile) {
            if (newFile && !oldFile) {
                // New file selected
            }
        },
        fileUrl(file) {
            return URL.createObjectURL(file.file);
        },
        isImage(file) {
            return file.type.startsWith('image/');
        },
        handleDrop(event) {
            event.preventDefault();
            // You can handle file data from event here if needed
            // For now, we're just preventing the default behavior
        },
        isFormComplete() {
            return this.files.length && this.promo.title && this.promo.ctaText && this.promo.ctaUrl && this.promo.disciplineId;
        },
        async submitForm() {
            if (!this.isFormComplete()) return;

            if (this.promo.ctaUrl.indexOf("://") === -1) {
                this.promo.ctaUrl = "https://" + this.promo.ctaUrl;
            }

            const formData = new FormData();
            formData.append('imageFile', this.files[0].file);
            formData.append('locale', this.promo.locale);
            formData.append('title', this.promo.title);
            formData.append('description', this.promo.description);
            formData.append('ctaText', this.promo.ctaText);
            formData.append('ctaUrl', this.promo.ctaUrl);
            formData.append('disciplineId', this.promo.disciplineId);

            try {
                const response = await uploadPromo(formData);
                this.message = response.success ? 'Upload successful!' : 'Error: ' + response.message;
                // Reset files after successful upload
                this.files = [];
                this.promo = {
                    locale: 'nl',
                    disciplineId: null,
                    title: null,
                    description: null,
                    ctaText: null,
                    ctaUrl: null
                };
                setTimeout(this.fetchPromos, 1000);
            } catch (error) {
                this.message = error.message;
            }
        },
        async fetchPromos() {
            try {
                this.promos = await fetchPromos();
            } catch (error) {
                // Handle errors, e.g., show an error message
            }
        },
        fixImageUrl(url) {
            return prefixImageUrl(url);
        }
    }
};
</script>

<style>
.upload-area {
    background: #f0f0f0;
    padding: 1em;
    margin-bottom: 2em;
    border-radius: 0.5em;
}

.file-preview {
    margin-top: 10px;
}

.thumbnail {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
}

.table {
    width: 100%;
    border-collapse: collapse;
    font-size: 10pt;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.table th {
    background-color: #f4f4f4;
}

.td-image {
    background-color: #ddd;
}
</style>
