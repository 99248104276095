<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">Register</div>
                    <div class="card-body">
                        <div v-if="error" class="alert alert-danger">{{ error }}</div>
                        <form action="#" @submit.prevent="Register">
                            <!-- <div class="form-group row">
                                <label for="name" class="col-md-4 col-form-label text-md-right">Name</label>

                                <div class="col-md-6">
                                    <input id="name" type="name" class="form-control" name="name" value required autofocus
                                        v-model="name" />
                                </div>
                            </div> -->

                            <div class="form-group row">
                                <label for="email" class="col-md-4 col-form-label text-md-right">Email</label>

                                <div class="col-md-6">
                                    <input id="email" type="email" class="form-control" name="email" value required
                                        autofocus v-model="email" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                                <div class="col-md-6">
                                    <input id="password" type="password" class="form-control" name="password" required
                                        v-model="password" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="repeat" class="col-md-4 col-form-label text-md-right">Repeat
                                    password</label>

                                <div class="col-md-6">
                                    <input id="repeat" type="password" class="form-control" name="repeat" required
                                        v-model="repeat" />
                                </div>
                            </div>

                            <div class="form-group row mb-0">
                                <div class="col-md-8 offset-md-4">
                                    <button type="submit" class="btn btn-primary">Register</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
    name: "RegisterView",
    setup() {
        // const name = ref('')
        const email = ref('')
        const password = ref('')
        const repeat = ref('')
        const error = ref(null)

        const store = useStore()
        const router = useRouter()

        const Register = async () => {
            try {
                if (password.value != repeat.value) {
                    error.value = 'Passwords do not match'
                    return
                }
                await store.dispatch('register', {
                    email: email.value,
                    password: password.value
                })
                router.push('/')
            } catch (err) {
                error.value = err.message
            }
        }

        return { Register, email, password, repeat, error }
    }
};
</script>
