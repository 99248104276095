import { createApp } from 'vue';
import App from './App.vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { BootstrapVue } from 'bootstrap-vue';

import '@/assets/styles/global.css';
import router from './router.js';
import store from './store.js';

const app = createApp(App);
app.use(BootstrapVue)
app.use(router)
app.use(store)
app.mount('#app');
