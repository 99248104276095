<template>
    <div>
        <h2 v-if="selectedDiscipline">Questions - {{ selectedDiscipline }}</h2>
        <h2 v-else>Questions</h2>

        <label for="locale-select">Choose a language:</label>

        <b-dropdown id="locale-select" :text="selectedLocale || 'Select language'" class="m-md-2">
            <b-dropdown-item @click="setLocale('nl')">NL - Nederlands</b-dropdown-item>
            <b-dropdown-item @click="setLocale('en')">EN - English</b-dropdown-item>
        </b-dropdown>

        <label for="discipline-select">and discipline:</label>

        <b-dropdown id="discipline-select" :text="selectedDiscipline || 'Select discipline'" class="m-md-2">
            <b-dropdown-item v-for="discipline in disciplines" :key="discipline.id"
                @click="setDiscipline(discipline.id)">{{ discipline.title
                }}</b-dropdown-item>
        </b-dropdown>

        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Question</th>
                    <th>Image</th>
                    <th>Answer A</th>
                    <th>Answer B</th>
                    <th>Answer C</th>
                    <th>Answer D</th>
                    <!-- <th>Correct Answer</th> -->
                    <th>Lesson</th>
                    <th>Times Answered</th>
                    <th>Correct</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="question in questions" :key="question.id">
                    <td>{{ question.id }}</td>
                    <td>{{ question.question }}</td>
                    <td class="td-image"><img v-if="question.imageUrl" :src="fixUrl(question.imageUrl)"
                            :alt="questions.title" width="100"></td>
                    <td :class="question.correctAnswer == 0 ? 'correct' : 'incorrect'">{{ question.answerA }}</td>
                    <td :class="question.correctAnswer == 1 ? 'correct' : 'incorrect'">{{ question.answerB }}</td>
                    <td :class="question.correctAnswer == 2 ? 'correct' : 'incorrect'">{{ question.answerC }}</td>
                    <td :class="question.correctAnswer == 3 ? 'correct' : 'incorrect'">{{ question.answerD }}</td>
                    <!-- <td>{{ question.correctAnswer }}</td> -->
                    <td>{{ parseInt(question.level) + 1 }}</td>
                    <td>{{ question.answered }}</td>
                    <td :class="difficultyClass(question)">{{ Math.round(100 * question.difficulty) }}%</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { fetchQuestions, fetchDetailedDisciplines, prefixImageUrl } from '@/repository/apiService';

export default {
    data() {
        return {
            questions: [],
            disciplines: [],
            selectedLocale: 'nl',
            selectedDiscipline: '',
            disciplineId: null
        };
    },
    created() {
        // this.fetchDisciplines();
        // this.fetchQuestions();
    },
    watch: {
        '$route.params.id': {
            immediate: true,
            handler(newVal) {
                this.disciplineId = newVal;
                this.fetchQuestions();
                this.fetchDisciplines(newVal);
            }
        }
    },
    methods: {
        setLocale(locale) {
            this.selectedLocale = locale;
            this.fetchDisciplines();
            this.fetchQuestions();
        },
        setDiscipline(disciplineId) {
            this.selectedDiscipline = this.disciplines.find(d => d.id === disciplineId).title;
            this.disciplineId = disciplineId;
            this.fetchQuestions();
        },
        async fetchDisciplines(newDisciplineId) {
            try {
                this.disciplines = await fetchDetailedDisciplines(this.selectedLocale);
                if (this.disciplineId != null) {
                    this.selectedDiscipline = this.disciplines.find(d => d.id == this.disciplineId).title;
                } else if (newDisciplineId) {
                    this.selectedDiscipline = this.disciplines.find(d => d.id == newDisciplineId).title;
                }
            } catch (error) {
                console.error('Error fetching disciplines:', error)
            }
        },
        async fetchQuestions() {
            try {
                this.questions = await fetchQuestions(this.selectedLocale, this.disciplineId);
            } catch (error) {
                // Handle errors, e.g., show an error message
            }
        },
        fixUrl(imageUrl) {
            return prefixImageUrl(imageUrl);
        },
        difficultyClass(question) {
            if (question.answered < 25) {
                return 'difficulty-not-answered';
            }
            if (question.difficulty < 0.05) {
                return 'difficulty-too-hard';
            } else if (question.difficulty < 0.10) {
                return 'difficulty-very-hard';
            } else if (question.difficulty < 0.25) {
                return 'difficulty-hard';
            } else if (question.difficulty > 0.75) {
                return 'difficulty-easy';
            } else if (question.difficulty > 0.90) {
                return 'difficulty-very-easy';
            } else if (question.difficulty > 0.95) {
                return 'difficulty-too-easy';
            }
        }
    }
};
</script>

<style>
td.correct {
    background-color: #aff6af;
}

.difficulty-too-hard {
    background-color: #d34d4d;
}

.difficulty-very-hard {
    background-color: #e77777;
}

.difficulty-very-easy {
    background-color: #ade4ba;
}

.difficulty-too-easy {
    background-color: #77c789;
}
</style>
