<template>
    <div>
        <h2>Create voucher codes</h2>

        <p>
            Press the button to add 100 new voucher codes to the database.
        </p>

        <form @submit.prevent="submitForm">
            <b-dropdown id="duration-select" :text="selectedDurationStr || 'Select subscription duration (days)'"
                class="m-md-2">
                <b-dropdown-item @click="selectedDuration = 7; selectedDurationStr = 'Week'">Week</b-dropdown-item>
                <b-dropdown-item @click="selectedDuration = 30; selectedDurationStr = 'Month'">Month</b-dropdown-item>
                <b-dropdown-item @click="selectedDuration = 180; selectedDurationStr = 'HalfYear'">6
                    Months</b-dropdown-item>
                <b-dropdown-item @click="selectedDuration = 365; selectedDurationStr = 'Year'">Year</b-dropdown-item>
            </b-dropdown>
            <button type="submit" class="button-main">Create voucher codes</button>
        </form>

        <div v-if="message" class="message">{{ message }}</div>

        <div v-if="newvouchers" class="newvouchers">
            <ul>
                <li v-for="voucher in newvouchers" :key="voucher">{{ voucher }}</li>
            </ul>
        </div>

        <br />
        <h2>Vouchers</h2>

        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Code</th>
                    <th>Claimed by user (if any)</th>
                    <th>Type</th>
                    <th>Duration (days)</th>
                    <th>Expires</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="voucher in vouchers" :key="voucher.id">
                    <td>{{ voucher.id }}</td>
                    <td>{{ voucher.code }}</td>
                    <td>{{ voucher.userId }}</td>
                    <td>{{ voucher.subscriptionType }}</td>
                    <td>{{ voucher.durationDays }}</td>
                    <td>{{ voucher.expiresAt }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { fetchVouchers, createVouchers } from '@/repository/apiService';

export default {
    data() {
        return {
            vouchers: [],
            newvouchers: [],
            message: '',
            success: false,
            selectedDuration: null,
            selectedDurationStr: null
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.vouchers = await fetchVouchers();
            } catch (error) {
                console.error('Error fetching vouchers:', error);
            }
        },
        async submitForm() {
            try {
                const response = await createVouchers(100, this.selectedDuration);
                this.message = response.success ? 'Created successfully! Copy-paste these codes:' : 'Error: ' + response.message;
                console.log(response);
                this.newvouchers = response.data;
                setTimeout(this.refresh, 1000);
            } catch (error) {
                this.message = error.message;
            }
        }
    }
};
</script>

<style>
.table {
    width: 100%;
    border-collapse: collapse;
    font-size: 10pt;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.table th {
    background-color: #f4f4f4;
}
</style>
