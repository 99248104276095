<template>
    <div class="form-area">
        <h2>Battle</h2>

        <form @submit.prevent="submitForm">
            <div class="form-group">
                <label for="title">Title</label>
                <input type="text" id="title" v-model="battle.title" class="form-control"
                    placeholder="Title (not visible in the app)">
            </div>

            <div class="form-group">
                <label for="discipline-select">Discipline (optional)</label><br />
                <b-dropdown id="discipline-select" :text="selectedDiscipline || 'No discipline'" class="m-md-2">
                    <b-dropdown-item v-for="discipline in disciplines" :key="discipline.id"
                        @click="setDiscipline(discipline.id)">{{ discipline.title
                        }}</b-dropdown-item>
                </b-dropdown>
            </div>

            <div class="form-group">
                <label for="description">Description (optional)</label>
                <textarea id="description" v-model="battle.description" class="form-control"></textarea>
            </div>
            <br />
            <button type="submit" class="button-main" :disabled="!battle.title">Create</button>
        </form>

        <div v-if="message" :class="{ 'alert-success': success, 'alert-danger': !success }">
            {{ message }}
        </div>
    </div>

    <BattlesTableView :key="battleKey" />
</template>

<script>
import BattlesTableView from './BattlesTableView.vue';
import { createBattle, fetchDetailedDisciplines } from '@/repository/apiService';

export default {
    components: {
        BattlesTableView
    },
    data() {
        return {
            battle: {
                title: '',
                description: '',
                disciplineId: null
            },
            message: '',
            success: false,
            battleKey: 0,
            selectedDiscipline: null,
            disciplines: []
        };
    },
    async created() {
        this.fetchDisciplines();
    },
    methods: {
        setDiscipline(disciplineId) {
            this.selectedDiscipline = this.disciplines.find(d => d.id === disciplineId).title;
            this.battle.disciplineId = disciplineId;
        },
        async fetchDisciplines() {
            try {
                const arr = await fetchDetailedDisciplines('en');
                this.disciplines = [{ title: 'No discipline', id: null }, ...arr];
                if (this.battle.disciplineId != null) {
                    this.selectedDiscipline = this.disciplines.find(d => d.id === this.battle.disciplineId).title;
                }
            } catch (error) {
                console.error('Error fetching disciplines:', error)
            }
        },
        async submitForm() {
            if (!this.battle.title) return;

            try {
                const response = await createBattle(this.battle);

                this.message = response.success ? 'Battle created!' : 'Error: ' + response.message;
                this.success = response.success;
                if (this.success) {
                    this.battle.title = '';
                    this.battle.description = '';
                    this.battleKey = Math.random();
                }
            } catch (error) {
                console.log(error);
                this.message = error.message;
                this.success = false;
            }
        }
    }
};
</script>

<style>
.form-area {
    background: #f0f0f0;
    padding: 1em;
    margin-bottom: 2em;
    border-radius: 0.5em;
}

.alert-danger,
.alert-success {
    padding: 1rem;
    border-radius: 6px;
}
</style>
