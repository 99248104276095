<template>
    <div>
        <h2>{{ battle.title }}</h2>

        <p>
            {{ battle.description }}
        </p>

        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Discipline</th>
                    <th>Created</th>
                    <th>Started</th>
                    <th>Ended</th>
                    <th>Active</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ battle.id }}</td>
                    <td v-if="battle.disciplineId">
                        <router-link :to="{ name: 'QuestionPerDiscipline', params: { id: battle.disciplineId } }">{{
            battle.disciplineId }}</router-link>
                    </td>
                    <td v-else>
                        N/A
                    </td>
                    <td>{{ battle.createdAt }}</td>
                    <td>{{ battle.startAt }}</td>
                    <td>{{ battle.endAt }}</td>
                    <td v-if="battle.isActive == 1">
                        <strong style="color: red;">ACTIVE BATTLE</strong><br />
                        <button class="button-danger" @click="stopBattle(battle.id)">Stop</button>
                    </td>
                    <td v-else>
                        <button class="button-main" @click="startBattle(battle.id)">Start</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <h3>Leaderboard</h3>

        <p v-if="leaderboard.length == 0">
            No winners yet
        </p>

        <table class="table" v-else>
            <thead>
                <tr>
                    <th>Position</th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>E-mail</th>
                    <th>Correct answers</th>
                    <th>Time ended</th>
                    <th>Time in seconds</th>
                    <th>Points</th>
                    <th>Language</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="winner in leaderboard" :key="winner.id">
                    <td>{{ winner.position }}</td>
                    <td>{{ winner.userId }}</td>
                    <td>{{ winner.name }}</td>
                    <td>{{ winner.email }}</td>
                    <td>{{ winner.questionsCorrect }}</td>
                    <td>{{ winner.createdAt }}</td>
                    <td>{{ Number(winner.timeTaken / 1000).toFixed(1) }}s</td>
                    <td>{{ winner.points }}</td>
                    <td>{{ winner.language }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { fetchBattle, fetchBattleLeaderboard, startBattle, stopBattle } from '@/repository/apiService';

export default {
    data() {
        return {
            battle: {
                title: '',
                description: '',
            },
            leaderboard: [],
            battleId: null
        };
    },
    created() {
        this.refresh();
    },
    watch: {
        '$route.params.id': {
            immediate: true,
            handler(newVal) {
                this.battleId = newVal;
                this.refresh();
            }
        }
    },
    methods: {
        async refresh() {
            try {

                this.battle = await fetchBattle(this.battleId);
                this.leaderboard = await fetchBattleLeaderboard(this.battleId);
            } catch (error) {
                // Handle errors, e.g., show an error message
                console.log(error);
            }
        },
        async startBattle(battleId) {
            try {
                await startBattle(battleId);
            } catch (error) {
                console.log(error);
            }
            this.refresh();
        },
        async stopBattle(battleId) {
            try {
                await stopBattle(battleId);
            } catch (error) {
                console.log(error);
            }
            this.refresh();
        }
    }
};
</script>

<style>
/* Your table styles */
</style>
