<template>
    <div>
        <h2>All images</h2>

        <table class="table">
            <!-- Create a row for every 4 images -->
            <tr v-for="(chunk, index) in chunkedImages" :key="`row-${index}`">
                <!-- Display an image in each cell -->
                <td v-for="(image, index) in chunk" :key="`image-${index}`" class="td-image">
                    <img :src="fixUrl(image)" :alt="`Image ${index}`" width="100">
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import { fetchAllMedia, prefixImageUrl } from '@/repository/apiService';

export default {
    data() {
        return {
            images: [],
        };
    },
    created() {
        this.fetchImages();
    },
    computed: {
        // Split the images array into chunks of 4 for the table rows
        chunkedImages() {
            let i, j, temparray, chunk = 4;
            const chunked = [];
            for (i = 0, j = this.images.length; i < j; i += chunk) {
                temparray = this.images.slice(i, i + chunk);
                chunked.push(temparray);
            }
            return chunked;
        },
    },
    methods: {
        async fetchImages() {
            try {
                this.images = await fetchAllMedia();
            } catch (error) {
                // Handle errors, e.g., show an error message
            }
        },
        fixUrl(imageUrl) {
            return prefixImageUrl(imageUrl);
        }
    }
};
</script>

<style>
/* Your table styles */
</style>
