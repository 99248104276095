<template>
    <div>
        <h2>Questions with images</h2>

        <label for="locale-select">Choose a language:</label>

        <b-dropdown id="locale-select" :text="selectedLocale || 'Select language'" class="m-md-2">
            <b-dropdown-item @click="setLocale('nl')">NL - Nederlands</b-dropdown-item>
            <b-dropdown-item @click="setLocale('en')">EN - English</b-dropdown-item>
        </b-dropdown>

        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Question</th>
                    <th>Image</th>
                    <th>Answer A</th>
                    <th>Answer B</th>
                    <th>Answer C</th>
                    <th>Answer D</th>
                    <th>Correct Answer</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="question in questions" :key="question.id">
                    <td>{{ question.id }}</td>
                    <td>{{ question.question }}</td>
                    <td class="td-image"><img :src="fixUrl(question.imageUrl)" :alt="questions.title" width="100"></td>
                    <td>{{ question.answerA }}</td>
                    <td>{{ question.answerB }}</td>
                    <td>{{ question.answerC }}</td>
                    <td>{{ question.answerD }}</td>
                    <td>{{ question.correctAnswer }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { fetchQuestionsWithMedia, prefixImageUrl } from '@/repository/apiService';

export default {
    data() {
        return {
            questions: [],
            selectedLocale: 'nl'
        };
    },
    created() {
        this.fetchQuestions();
    },
    methods: {
        setLocale(locale) {
            this.selectedLocale = locale;
            this.fetchQuestions();
        },
        async fetchQuestions() {
            try {
                this.questions = await fetchQuestionsWithMedia(this.selectedLocale);
            } catch (error) {
                // Handle errors, e.g., show an error message
            }
        },
        fixUrl(imageUrl) {
            return prefixImageUrl(imageUrl);
        }
    }
};
</script>

<style>
/* Your table styles */
</style>
